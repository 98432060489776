import * as React from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { PortalsSetup } from '../PortalsSetup'
import { App } from './App'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})

export function AppSetup() {
  return (
    <QueryClientProvider client={client}>
      <PortalsSetup>
        <App />
      </PortalsSetup>
    </QueryClientProvider>
  )
}
