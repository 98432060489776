import * as React from 'react'
import {
  CheckCircleSelectedFillIcon,
  CheckCircleUnselectedIcon
} from '@toasttab/buffet-pui-icons'
import { permissionOn } from '../../utils/permissionUtil'

export interface ReadonlyPermissionProps {
  permissions: BigInt
  position: number
}

export function ReadonlyPermission({
  permissions,
  position
}: ReadonlyPermissionProps) {
  if (permissionOn(permissions, position)) {
    return (
      <CheckCircleSelectedFillIcon
        accessibility='decorative'
        size='md'
        className='text-success'
        testId={'permissions-selected-' + position}
      />
    )
  } else {
    return (
      <CheckCircleUnselectedIcon
        accessibility='decorative'
        size='md'
        className='text-gray-110'
        testId={'permissions-unselected-' + position}
      />
    )
  }
}
