import * as React from 'react'
import { Dispatch } from 'react'
import { CheckboxGroup } from '@toasttab/buffet-pui-checkbox'
import { PermissionsInit } from '../../api/permission'
import { EditorAccess, TOAST_ADMIN_ROLE } from '../../utils/editorAccess'

/**
 * Updating roles resets overrides, but this should only
 * reset for permissions not covered by existing roles
 *
 * O = Override is on (1)
 * ER = permission is on (1) in Existing Roles, excluding updated role
 * UR = permission is on (1) for Updated Role
 *
 * O ER UR | Overrides to Keep = O ^ (ER v ~R)
 * 1  1  1 | 1
 * 1  1  0 | 1
 * 1  0  1 | 0
 * 1  0  0 | 1
 * 0  1  1 | 0
 * 0  1  0 | 0
 * 0  0  1 | 0
 * 0  0  0 | 0
 */
export function userOverridesAfterRoleChange(
  allRolesAndPermissions: PermissionsInit,
  userRoles: string[],
  modifiedRole: string,
  userOverrides: BigInt
): BigInt {
  let existingRolePermissions = BigInt(0)
  allRolesAndPermissions.roles
    .filter((r) => userRoles.includes(r.name) && r.name !== modifiedRole)
    .map(({ permissions }) => BigInt('0b' + permissions))
    .forEach((r) => (existingRolePermissions = existingRolePermissions | r))
  const rolePermissions = BigInt(
    '0b' +
      allRolesAndPermissions.roles.filter((r) => r.name === modifiedRole)[0]
        .permissions
  )
  const overridesToKeep = existingRolePermissions | ~rolePermissions
  // @ts-ignore -- operation mistakenly says a `bitint` is returned instead of `BigInt`
  return userOverrides & overridesToKeep
}

export function isRoleDisabled(editorAccess: EditorAccess, userRole: string) {
  return !(
    editorAccess.universalAccess ||
    (editorAccess.administratorManagement && userRole !== TOAST_ADMIN_ROLE)
  )
}

export interface RoleGroupProps {
  userRoles: string[]
  setUserRoles: Dispatch<string[]>
  userOverrides: BigInt
  setUserOverrides: Dispatch<bigint>
  allRolesAndPermissions: PermissionsInit
  editorAccess: EditorAccess
}

export function RoleGroup({
  userRoles,
  setUserRoles,
  userOverrides,
  setUserOverrides,
  allRolesAndPermissions,
  editorAccess
}: RoleGroupProps) {
  const onRoleChange = React.useCallback(
    (e: any) => {
      const value = e?.target?.value
      const set = new Set(userRoles)
      var addingAdminRole = false
      if (set.has(value)) {
        set.delete(value)
      } else {
        set.add(value)
        if (value === TOAST_ADMIN_ROLE) {
          addingAdminRole = true
        }
      }

      if (addingAdminRole) {
        setUserOverrides(BigInt(0))
      } else {
        setUserOverrides(
          // @ts-ignore -- without the ignore 'bigint' is assumed as a passed parameter
          userOverridesAfterRoleChange(
            allRolesAndPermissions,
            userRoles,
            value,
            userOverrides
          )
        )
      }

      setUserRoles(Array.from(set))
    },
    [
      userRoles,
      userOverrides,
      allRolesAndPermissions,
      setUserOverrides,
      setUserRoles
    ]
  )

  const userRolesCheckBoxes = allRolesAndPermissions.roles.map(
    ({ name, title }) => ({
      checked: userRoles.includes(name),
      label: title,
      value: name,
      key: name,
      disabled: isRoleDisabled(editorAccess, name)
    })
  )

  return (
    <CheckboxGroup
      label='Roles'
      onChange={onRoleChange}
      options={userRolesCheckBoxes}
    />
  )
}
