import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export function LoadingState() {
  return (
    <div className='m-auto w-16'>
      <MerryGoRound testId='loading-animation' size='lg' />
    </div>
  )
}
