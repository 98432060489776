/**
 * Returns if a permission at position is on (1)
 */
export function permissionOn(permissions: BigInt, position: number): boolean {
  const positionValue: BigInt = BigInt(1) << BigInt(position)
  // @ts-ignore -- operation mistakenly says a `bitint` is returned instead of `BigInt`
  const permissionValue: BigInt = permissions & positionValue
  return permissionValue !== BigInt(0)
}

/**
 * Flips a permission from on (1) to off (0) and vise versa.
 *
 * Examples:
 * For each example, the following is the default permissions value.
 * permissions = 10
 * ----
 * Example at position 0
 * position = 0
 * position value = 01
 * result = 11
 * ----
 * Example at position 1
 * position = 1
 * position value = 10
 * result = 00
 *
 * @param permissions permissions to toggle
 * @param position bit position to toggle
 */
export function togglePermissionBit(
  permissions: BigInt,
  position: number
): BigInt {
  const positionValue: BigInt = BigInt(1) << BigInt(position)
  // @ts-ignore -- operation mistakenly says a `bitint` is returned instead of `BigInt`
  return permissions ^ positionValue
}

/**
 * Cleans digits more significant than the largest permission bit.
 *
 * Example:
 * permission = 1111
 * largestPermissionBit = 2
 * result =     0111
 *
 * @param permissions permissions to be cleaned
 * @param largestPermissionBit clean any bits more significant than this position
 */
export function cleanPermissions(
  permissions: BigInt,
  largestPermissionBit: number
): BigInt {
  let validPermissionRange: BigInt = BigInt(0)
  /*
   * Ideally we'd do something like:
   * Math.pow(BigInt(2), BigInt(largestPermissionBit + 1)) - 1
   */
  for (let i = 0; i <= largestPermissionBit; i++) {
    // @ts-ignore -- without the ignore 'bigint' is assumed as a passed parameter
    validPermissionRange |= BigInt(1) << BigInt(i)
  }
  // @ts-ignore -- operation mistakenly says a `bitint` is returned instead of `BigInt`
  return permissions & validPermissionRange
}

/**
 * @returns the largest permission bit in an array of numbers
 */
export function largestPermissionBit(permissionBits: number[]): number {
  return Math.max(...permissionBits)
}
