import * as React from 'react'
import { UserViewAction } from './UserViewAction'
import { disableMfa, DISABLE_MFA_KEY } from '../../api/mfa'

interface DisableMfaProps {
  userGuid: string
}

export function DisableMfa({ userGuid }: DisableMfaProps) {
  return (
    <UserViewAction
      actionLabel='Disable MFA'
      modalHeader='Are you sure you want to disable multi-factor authentication?'
      modalBodyContent={<DisableMfaModalBody />}
      errorMessage='Multi-factor authentication could not be disabled'
      successMessage='Multi-factor authentication disabled'
      userGuid={userGuid}
      mutationKey={DISABLE_MFA_KEY}
      mutationFn={disableMfa}
    />
  )
}

const DisableMfaModalBody = () => (
  <>
    <p>Disabling multi-factor authentication will result in:</p>
    <ul className='list-inside list-disc px-2'>
      <li>Not be prompted for a one time code on log in</li>
      <li>Previous multi-factor authentication set up to be voided</li>
      <li>Previous recovery code to be voided</li>
      <li>Their account to be less secure</li>
    </ul>
    <p className='mt-4'>
      Users can always re-enable MFA at any time within their My Account page.
    </p>
  </>
)
