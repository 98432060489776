import * as React from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { SearchIcon, LookupInfoIcon } from '@toasttab/buffet-pui-icons'

export function NoUserFound() {
  return (
    <EmptyState
      icon={<LookupInfoIcon accessibility='decorative' />}
      title='No user found'
    >
      Try updating your search with exact values to find your user
    </EmptyState>
  )
}

export function NoUserSelected() {
  return (
    <EmptyState
      icon={<SearchIcon accessibility='decorative' />}
      title='No user selected'
    >
      Find a specific user by using the above fields to obtain an exact match
    </EmptyState>
  )
}
