import * as React from 'react'
import { Table, Body, Row, Cell } from '@toasttab/buffet-pui-table'
import { Badge } from '@toasttab/buffet-pui-badge'
import { User, ID_VERIFICATION_STATUS } from '../../api/user'
import { DisableMfa, UnverifyPhone, UnverifyIdentity } from '../Actions'
import { Header } from './Header'
import { Button } from '@toasttab/buffet-pui-buttons'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { TextWithOptionalTooltip } from '../AdministratorAccess/TextWithOptionalTooltip'

interface DataRowProps {
  field: string
  data: React.ReactNode
  children?: React.ReactNode
  fieldTooltipMessage?: string
}

function DataRow({ field, data, children, fieldTooltipMessage }: DataRowProps) {
  return (
    <Row>
      {/* Need align middle as actions are bigger than a normal row */}
      <Cell className='w-1/5'>
        <TextWithOptionalTooltip text={field} tooltip={fieldTooltipMessage} />
      </Cell>
      <Cell className='w-2/5 select-all'>{data}</Cell>
      <Cell className='w-2/5 md:py-0'>{children}</Cell>
    </Row>
  )
}

export interface ViewUserProps {
  user: User
}

export function ViewUser({
  user: {
    firstName,
    lastName,
    email,
    guid,
    adminAccess,
    phoneNumber,
    phoneNumberCountryCode,
    isPhoneNumberVerified,
    hasMfa,
    lastIdVerificationInquiryID,
    lastIdVerificationStatus,
    lastIdVerificationDate,
    createdDate,
    hasBackendAccess
  }
}: ViewUserProps) {
  // Not using `react-table` here since the rows themselves are static, but the
  // cells data is dynamic. Same for actions, it would be tricky since they are
  // different based on rows.
  return (
    <Table valign='middle'>
      <Header />
      <Body>
        <DataRow field='First name' data={firstName} />
        <DataRow field='Last name' data={lastName} />
        <DataRow field='Email address' data={email} />
        <DataRow
          field='Date created'
          data={format(new Date(createdDate), Formats.date.long)}
        />
        <DataRow
          field='Account status'
          data={
            !!hasBackendAccess ? 'Activated' : 'Not activated (POS access only)'
          }
          fieldTooltipMessage={
            'A user account is active once the user has successfully set a password and activated the account'
          }
        />
        <DataRow
          field='GUID'
          data={<p className='whitespace-nowrap'>{guid}</p>}
        />
        <DataRow
          field='User type'
          data={
            adminAccess.roles.some((r) => r.startsWith('TOAST_'))
              ? 'Toast Administrator'
              : 'Customer'
          }
        />
        <DataRow
          field='Phone number'
          data={
            <>
              {phoneNumber ? `${phoneNumberCountryCode} ${phoneNumber}` : '-'}
              <Badge
                className='ml-2'
                color={isPhoneNumberVerified ? 'success' : 'error'}
              >
                {isPhoneNumberVerified ? 'Verified' : 'Not Verified'}
              </Badge>
            </>
          }
        >
          {isPhoneNumberVerified && <UnverifyPhone userGuid={guid} />}
        </DataRow>
        <DataRow field='MFA Status' data={hasMfa ? 'Enabled' : 'Disabled'}>
          {hasMfa && <DisableMfa userGuid={guid} />}
        </DataRow>
        <DataRow
          field='Identity Verification'
          data={
            <span className='whitespace-nowrap'>
              {lastIdVerificationDate && (
                <span className='mr-2'>
                  {format(
                    new Date(lastIdVerificationDate),
                    Formats.dateTime.medium
                  )}
                </span>
              )}

              <IdVerificationBadge
                verificationStatus={lastIdVerificationStatus}
              />
            </span>
          }
        >
          {lastIdVerificationStatus && <UnverifyIdentity userGuid={guid} />}
          {lastIdVerificationInquiryID && (
            <ViewInquiryButton inquiryID={lastIdVerificationInquiryID} />
          )}
        </DataRow>
      </Body>
    </Table>
  )
}

const ViewInquiryButton = ({ inquiryID }: { inquiryID: string }) => {
  return (
    <Button
      className='px-0'
      iconLeft={<LaunchIcon accessibility='decorative' />}
      variant='link'
      onClick={() =>
        window.open(
          `https://app.withpersona.com/dashboard/inquiries/${inquiryID}`,
          '_blank'
        )
      }
    >
      View Identity Verification Details
    </Button>
  )
}
const IdVerificationBadge = ({
  verificationStatus
}: {
  verificationStatus?: ID_VERIFICATION_STATUS | null
}) => {
  let badgeColor, badgeText
  switch (verificationStatus) {
    case ID_VERIFICATION_STATUS.SUCCESS:
      badgeText = 'Identity Verified'
      badgeColor = Badge.Color.success
      break
    case ID_VERIFICATION_STATUS.FAILED:
      badgeText = 'Verification pending review'
      badgeColor = Badge.Color.warning
      break
    case ID_VERIFICATION_STATUS.FAILED_PERMANENTLY:
      badgeText = 'Verification failed'
      badgeColor = Badge.Color.error
      break
    case ID_VERIFICATION_STATUS.REVERIFICATION_REQUESTED:
      badgeText = 'Reverification requested'
      badgeColor = Badge.Color.info
      break
    default:
      badgeText = 'Identity Unverified'
      badgeColor = Badge.Color.info
  }

  return <Badge color={badgeColor}>{badgeText}</Badge>
}
