import { ApiError } from '../types/ApiError'
import { METHOD_DELETE, ROUTE_USERS_PHONE_VERIFICATION } from './constants'

export const UNVERIFY_PHONE_KEY = 'unverify_phone_key'

export async function unverifyPhone(userGuid: string) {
  const response = await fetch(ROUTE_USERS_PHONE_VERIFICATION, {
    method: METHOD_DELETE,
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ userGuid })
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }
}
