import { isValidEmail } from '@toasttab/email-validation'
import { object, string } from 'yup'
import { Criterion } from '../../api/user'
import { validationSchema } from '@toasttab/buffet-pui-phone-input'

export const CRITERIA: { value: Criterion; label: string }[] = [
  {
    value: 'email',
    label: 'Email address'
  },
  {
    value: 'guid',
    label: 'User Identifier'
  },
  {
    value: 'phone',
    label: 'Verified Phone Number'
  }
]

export const criteriaValues = CRITERIA.map(({ value }) => value)

export const FORM_SCHEMA = object().shape({
  search: string().when('criterion', {
    is: 'email',
    then: (schema) =>
      schema.test(
        'email',
        'Please enter a valid email address',
        (search) => !!search && isValidEmail(search)
      )
  }),
  phone: object()
    .shape({
      countryCode: string(),
      nationalNumber: string()
    })
    .when('criterion', {
      is: 'phone',
      then: () => validationSchema
    })
})
