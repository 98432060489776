import * as React from 'react'
import { UserViewAction } from './UserViewAction'
import { unverifyPhone, UNVERIFY_PHONE_KEY } from '../../api/phoneVerification'

interface UnverifyPhoneProps {
  userGuid: string
}

export function UnverifyPhone({ userGuid }: UnverifyPhoneProps) {
  return (
    <UserViewAction
      actionLabel='Unverify phone'
      modalHeader='Are you sure you want to unverify this phone number?'
      modalBodyContent={<UnverifyPhoneModalBody />}
      errorMessage='Phone number could not be unverified'
      successMessage='Phone number successfuly unverified'
      userGuid={userGuid}
      mutationKey={UNVERIFY_PHONE_KEY}
      mutationFn={unverifyPhone}
    />
  )
}

const UnverifyPhoneModalBody = () => (
  <p>
    Users can always re-verify their phone number any time within their My
    Account page. Users will not be able to recieve one time codes until they
    reverify a phone number
  </p>
)
