import { ApiError } from '../types/ApiError'
import { METHOD_GET, ROUTE_USERS_CUSTOMERS } from './constants'
import { getMfaState } from './mfa'
import { AdminAccess, getAdminAccess } from './administrator'
import { PhoneNumber } from '@toasttab/buffet-pui-phone-input'

// https://github.com/toasttab/toast-users/blob/main/toast-users-api/src/main/kotlin/com/toasttab/service/users/api/models/idverification/VerificationStatus.kt
export enum ID_VERIFICATION_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  FAILED_PERMANENTLY = 'FAILED_PERMANENTLY',
  REVERIFICATION_REQUESTED = 'REVERIFICATION_REQUESTED'
}

// https://github.com/toasttab/toast-users/blob/main/toast-users-api/src/main/kotlin/com/toasttab/service/users/api/responses/customers/CustomerNameResponse.kt
export interface CustomerIdentifier {
  userGuid: string
}

// https://github.com/toasttab/toast-users/blob/main/toast-users-api/src/main/kotlin/com/toasttab/service/users/api/UserData.kt
export interface Customer {
  guid: string
  externalId?: string
  deleted?: boolean
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  isPhoneNumberVerified?: boolean
  firstName?: string
  chosenName?: string
  lastName?: string
  lastIdVerificationDate?: string | null
  lastIdVerificationInquiryID?: string | null
  lastIdVerificationStatus?: ID_VERIFICATION_STATUS | null
  createdDate: number
  hasBackendAccess: boolean
}

export interface User {
  firstName?: string
  chosenName?: string
  lastName?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  isPhoneNumberVerified?: boolean
  email?: string
  guid: string
  hasMfa: boolean
  adminAccess: AdminAccess
  lastIdVerificationDate?: string | null
  lastIdVerificationInquiryID?: string | null
  lastIdVerificationStatus?: ID_VERIFICATION_STATUS | null
  createdDate: number
  hasBackendAccess: boolean
}
export type SearchUserParams =
  | { search: string; criterion: 'email' | 'guid' }
  | { search: PhoneNumber; criterion: 'phone' }

export type OptionalUser = User | null

export type Criterion = 'email' | 'guid' | 'phone'

export const SEARCH_USER_KEY = 'search_user_key'

function transformUserRep(
  {
    email,
    firstName,
    chosenName,
    lastName,
    guid,
    phoneNumber,
    phoneNumberCountryCode,
    isPhoneNumberVerified,
    lastIdVerificationDate,
    lastIdVerificationInquiryID,
    lastIdVerificationStatus,
    createdDate,
    hasBackendAccess
  }: Customer,
  hasMfa: boolean,
  adminAccess: AdminAccess
): User {
  return {
    firstName,
    chosenName,
    lastName,
    email,
    guid,
    phoneNumber,
    phoneNumberCountryCode,
    isPhoneNumberVerified,
    lastIdVerificationDate,
    lastIdVerificationInquiryID,
    lastIdVerificationStatus,
    hasMfa,
    adminAccess,
    createdDate,
    hasBackendAccess
  }
}

async function fetchData(url: string) {
  const response = await fetch(url, {
    method: METHOD_GET
  })

  if (response.status === 204 || response.status === 404) {
    return null
  }

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }

  return await response.json()
}

async function getIdentifierByEmail(
  email: string
): Promise<CustomerIdentifier | null> {
  const url = `${ROUTE_USERS_CUSTOMERS}/getUserGuidByEmail/${encodeURIComponent(
    email
  )}`
  return await fetchData(url)
}

async function getCustomerByVerifiedPhone(
  phone: PhoneNumber
): Promise<Customer | null> {
  const encodedCountryCode = encodeURIComponent(`+${phone.countryCode!}`)
  const encodedPhoneNumber = encodeURIComponent(
    phone.nationalNumber!.replace(/^0+/, '')
  )
  const url = `${ROUTE_USERS_CUSTOMERS}/by/phone/verified?countryCode=${encodedCountryCode}&phoneNumber=${encodedPhoneNumber}`
  return await fetchData(url)
}

async function getCustomerByGuid(guid?: string): Promise<Customer | null> {
  if (!guid) return null
  const url = `${ROUTE_USERS_CUSTOMERS}/${guid}?userAccessType=TOAST_WEB_ACCESS&includeDeleted=true&includeExternalId=true`
  return await fetchData(url)
}

/**
 * Can return null if no user matches the criterion. Doesn't allow (yet) for
 * wildcard queries. It can only return one user.
 */

export async function searchUser(
  searchParams: SearchUserParams
): Promise<OptionalUser> {
  if (searchParams.criterion === 'email') {
    const userGuid = (await getIdentifierByEmail(searchParams.search))?.userGuid
    const customer = await getCustomerByGuid(userGuid)
    return transformCustomer(customer)
  } else if (searchParams.criterion === 'guid') {
    const customer = await getCustomerByGuid(searchParams.search)
    return transformCustomer(customer)
  } else if (searchParams.criterion === 'phone') {
    const customer = await getCustomerByVerifiedPhone(searchParams.search)
    return transformCustomer(customer)
  } else {
    return null
  }
}

const transformCustomer = async (customer: Customer | null) => {
  if (!customer) return null

  const adminAccess = await getAdminAccess(customer.guid)
  const mfaState = await getMfaState(customer.guid)
  return transformUserRep(customer, mfaState.status, adminAccess)
}
