import * as React from 'react'
import { useQuery } from 'react-query'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useBanquetProps } from 'banquet-runtime-modules'
import { PERMISSIONS_INIT_KEY, permissionsInit } from '../../api/permission'
import { AdminAccess } from '../../api/administrator'
import { getEditorAccess } from '../../utils/editorAccess'
import { RoleGroup } from './RoleGroup'
import { PermissionsTable } from './PermissionsTable'
import { SaveAccessButton } from './SaveAccessButton'
import { largestPermissionBit } from '../../utils/permissionUtil'

function useRolesAndPermissions() {
  const { showErrorSnackBar } = useSnackBar()

  return useQuery(PERMISSIONS_INIT_KEY, permissionsInit, {
    onError(e) {
      showErrorSnackBar(String(e), {
        onDismiss() {}
      })
    }
  })
}

export interface AdministratorAccessProps {
  userGuid: string
  access: AdminAccess
}

export function AdministratorAccess({
  userGuid,
  access
}: AdministratorAccessProps) {
  const { auth } = useBanquetProps()
  const { data: allRolesAndPermissions } = useRolesAndPermissions()

  const [userRoles, setUserRoles] = React.useState(access.roles)
  const [userOverrides, setUserOverrides] = React.useState(
    BigInt(access.overrideFlags)
  )
  const [userInheritedPermissions, setUserInheritedPermissions] =
    React.useState(BigInt(0))

  React.useEffect(() => {
    let updatedInheritedPermissions = BigInt(0)
    allRolesAndPermissions?.roles
      .filter((r) => userRoles.includes(r.name))
      .map(({ permissions }) => BigInt('0b' + permissions))
      .forEach(
        (r) => (updatedInheritedPermissions = updatedInheritedPermissions | r)
      )
    setUserInheritedPermissions(updatedInheritedPermissions)
  }, [userRoles, allRolesAndPermissions])

  const editorRoles = auth?.userInfo.roles
  const editorPermissions = auth?.userInfo.resolvedToastPermissions

  if (!!allRolesAndPermissions && !!editorRoles && !!editorPermissions) {
    const editorAccess = getEditorAccess(editorRoles, editorPermissions)
    const largestBit = largestPermissionBit(
      allRolesAndPermissions.administrativePermissions.map((p) => p.positionBit)
    )

    return (
      <>
        <SaveAccessButton
          editorAccess={editorAccess}
          largestPermissionBit={largestBit}
          userGuid={userGuid}
          userInheritedPermissions={userInheritedPermissions}
          userOverrides={userOverrides}
          userRoles={userRoles}
        />
        <RoleGroup
          userRoles={userRoles}
          setUserRoles={setUserRoles}
          userOverrides={userOverrides}
          setUserOverrides={setUserOverrides}
          allRolesAndPermissions={allRolesAndPermissions}
          editorAccess={editorAccess}
        />
        <Alert className='my-4' testId='role-info-alert'>
          <p className='mb-2'>
            Note that changing roles will reset affected overrides of inherited
            values.
          </p>
          <p>
            The reset will only affect overrides applicable to the role being
            added or removed, but respects overrides applicable to existing
            roles.
          </p>
        </Alert>
        <div className='sticky top-0 z-10 h-8 bg-white font-bold'>
          Permissions
        </div>
        <PermissionsTable
          userInheritedPermissions={userInheritedPermissions}
          userOverrides={userOverrides}
          allPermissions={allRolesAndPermissions.administrativePermissions.sort(
            (a, b) => a.positionBit - b.positionBit
          )}
          editorAccess={editorAccess}
          userRoles={userRoles}
          setUserOverrides={setUserOverrides}
        />
      </>
    )
  } else {
    return (
      <Alert variant='error' testId='roles-and-permissions-error-alert'>
        An error most likely occurred retrieving all role and permission
        descriptions.
      </Alert>
    )
  }
}
