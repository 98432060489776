import * as React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { patchAdminAccess, PATCH_ADMIN_ACCESS } from '../../api/administrator'
import { EditorAccess, TOAST_ADMIN_ROLE } from '../../utils/editorAccess'
import { ApiError } from '../../types/ApiError'
import { cleanPermissions } from '../../utils/permissionUtil'

export interface SaveAccessButtonProps {
  editorAccess: EditorAccess
  largestPermissionBit: number
  userGuid: string
  userInheritedPermissions: BigInt
  userOverrides: BigInt
  userRoles: string[]
}

export function SaveAccessButton({
  editorAccess,
  largestPermissionBit,
  userGuid,
  userInheritedPermissions,
  userOverrides,
  userRoles
}: SaveAccessButtonProps) {
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(
    PATCH_ADMIN_ACCESS,
    () =>
      patchAdminAccess(userGuid, {
        roles: userRoles,
        overrideFlags: cleanPermissions(userOverrides, largestPermissionBit),
        overrides: cleanPermissions(
          // @ts-ignore -- without the ignore 'bigint' is assumed as a passed parameter
          userInheritedPermissions ^ userOverrides,
          largestPermissionBit
        )
      }),
    {
      onError(error: ApiError) {
        showErrorSnackBar(
          error?.message || 'An error occurred updating administrative access',
          {
            onDismiss() {}
          }
        )
      },
      onSuccess() {
        queryClient.invalidateQueries()
        showSuccessSnackBar('Administrative access updated successfully', {
          onDismiss() {}
        })
      }
    }
  )

  return (
    <div className='sticky right-0 top-0 z-20 float-right'>
      <Button
        disabled={
          isLoading ||
          !(
            editorAccess.universalAccess ||
            (editorAccess.administratorManagement &&
              !userRoles.includes(TOAST_ADMIN_ROLE))
          )
        }
        onClick={() => mutate()}
        testId='save-access-button'
      >
        Save
      </Button>
    </div>
  )
}
