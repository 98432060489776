import * as React from 'react'
import { useQuery } from 'react-query'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { Criterion, SEARCH_USER_KEY, searchUser } from '../../api/user'
import { MainContent } from '../MainContent'
import { SearchUserForm } from '../SearchUserForm'
import { FORM_SCHEMA } from '../SearchUserForm/schema'
import { getInitialValuesFromParam } from '../../utils/params'
import {
  Page,
  PageBody,
  PageHeader,
  Title,
  Panel
} from '@toasttab/buffet-pui-config-templates'
import { PhoneNumber } from '@toasttab/buffet-pui-phone-input'

function isQueryEnabled(
  search: string,
  phone: PhoneNumber,
  criterion: Criterion,
  fieldsValid: boolean
) {
  if (!fieldsValid) {
    return false
  } else if (
    criterion === 'phone' &&
    !!phone.countryCode &&
    !!phone.nationalNumber
  ) {
    return true
  } else if (!!search) {
    return true
  } else {
    return false
  }
}

function useSearchUserForm() {
  const [values, setValues] = React.useState<{
    search: string
    phone: PhoneNumber
    criterion: Criterion
  }>(getInitialValuesFromParam())

  const { showErrorSnackBar } = useSnackBar()
  const fieldsValid = FORM_SCHEMA.isValidSync(values)
  const { search, phone, criterion } = values

  const userResults = useQuery(
    [SEARCH_USER_KEY, values],
    () =>
      searchUser(
        criterion === 'phone'
          ? { search: phone, criterion }
          : { search, criterion }
      ),
    {
      enabled: isQueryEnabled(search, phone, criterion, fieldsValid),
      onError(e) {
        showErrorSnackBar(String(e), { onDismiss() {} })
      }
    }
  )

  return {
    ...userResults,
    setQueryVariables: setValues,
    search,
    phone,
    criterion
  }
}

export function App() {
  const {
    data: user,
    status,
    setQueryVariables,
    search,
    phone,
    criterion
  } = useSearchUserForm()
  return (
    <Page className=''>
      <PageHeader>
        <Title>Debug User</Title>
      </PageHeader>
      <PageBody>
        <Panel>
          <SearchUserForm
            isLoading={status === 'loading'}
            search={search}
            phone={phone}
            criterion={criterion}
            onSubmit={(values) => setQueryVariables(values)}
          />
          <MainContent status={status} user={user} />
        </Panel>
      </PageBody>
    </Page>
  )
}
