import { ApiError } from '../types/ApiError'
import { METHOD_GET, METHOD_PATCH, ROUTE_USERS_ADMIN } from './constants'

/**********************************
 * GET administrator access
 **********************************/

export interface AdminAccess {
  guid: string
  roles: string[]
  overrideFlags: string
  overrides: string
}

export async function getAdminAccess(guid: string): Promise<AdminAccess> {
  const response = await fetch(`${ROUTE_USERS_ADMIN}/${guid}/access`, {
    method: METHOD_GET
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }

  return (await response.json()) as AdminAccess
}

/**********************************
 * PATCH administrator access
 **********************************/

export const PATCH_ADMIN_ACCESS = 'patch_admin_access'

export interface AccessRequest {
  roles: string[]
  overrideFlags: BigInt
  overrides: BigInt
}

export async function patchAdminAccess(guid: string, request: AccessRequest) {
  const response = await fetch(`${ROUTE_USERS_ADMIN}/${guid}/access`, {
    method: METHOD_PATCH,
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      roles: request.roles,
      overrideFlags: request.overrideFlags.toString(),
      overrides: request.overrides.toString()
    })
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }
}
