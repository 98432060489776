import * as React from 'react'
import { Dispatch } from 'react'
import { useTable, Column, CellProps } from 'react-table'
import {
  Table,
  Head,
  Row,
  HeadingCell,
  Body,
  Cell
} from '@toasttab/buffet-pui-table'
import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { PermissionsEnum } from '../../api/permission'
import { EditorAccess, TOAST_ADMIN_ROLE } from '../../utils/editorAccess'
import { permissionOn, togglePermissionBit } from '../../utils/permissionUtil'
import { ReadonlyPermission } from './ReadonlyPermission'
import { TextWithOptionalTooltip } from './TextWithOptionalTooltip'

export interface PermissionsTableProps {
  allPermissions: PermissionsEnum[]
  editorAccess: EditorAccess
  userInheritedPermissions: BigInt
  userOverrides: BigInt
  setUserOverrides: Dispatch<bigint>
  userRoles: string[]
}

export function PermissionsTable({
  userInheritedPermissions,
  userOverrides,
  allPermissions,
  editorAccess,
  setUserOverrides,
  userRoles
}: PermissionsTableProps) {
  const columns: Column<PermissionsEnum>[] = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'positionBit',
        id: 'permissions-table-bit-position'
      },
      {
        Header: 'Name',
        id: 'permissions-table-name',
        Cell: ({ row }: CellProps<PermissionsEnum>) => (
          <TextWithOptionalTooltip
            text={row.original.title}
            tooltip={row.original.description}
          />
        )
      },
      {
        Header: 'Inherited',
        id: 'permissions-table-inherited',
        Cell: ({ row }: CellProps<PermissionsEnum>) => (
          <ReadonlyPermission
            permissions={userInheritedPermissions}
            position={row.original.positionBit}
          />
        )
      },
      {
        Header: 'Overrides Inherited',
        id: 'permissions-table-overrides',
        Cell: ({ row }: CellProps<PermissionsEnum>) => (
          <ToggleSwitch
            isActive={
              !userRoles.includes(TOAST_ADMIN_ROLE) &&
              permissionOn(userOverrides, row.original.positionBit)
            }
            disabled={
              userRoles.includes(TOAST_ADMIN_ROLE) ||
              !(
                editorAccess.universalAccess ||
                editorAccess.administratorManagement
              )
            }
            onChange={() =>
              setUserOverrides(
                // @ts-ignore -- without the ignore 'bigint' is assumed as a passed parameter
                togglePermissionBit(userOverrides, row.original.positionBit)
              )
            }
            aria-label={row.original.title}
            testId={'permissions-table-overrides-' + row.original.positionBit}
          />
        )
      },
      {
        Header: 'Resolved',
        id: 'permissions-table-resolved',
        Cell: ({ row }: CellProps<PermissionsEnum>) => (
          <ReadonlyPermission
            // @ts-ignore -- without the ignore 'bigint' is assumed as a passed parameter
            permissions={userInheritedPermissions ^ userOverrides}
            position={row.original.positionBit}
          />
        )
      }
    ],
    [
      userInheritedPermissions,
      userOverrides,
      userRoles,
      editorAccess,
      setUserOverrides
    ]
  )

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: allPermissions
  })

  return (
    <Table testId='permissions-table'>
      <Head className='sticky z-10 bg-white' style={{ top: '32px' }}>
        {headerGroups.map((headerGroup) => (
          <Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <HeadingCell key={'permissions-table-header-cell-' + index}>
                {column.render('Header')}
              </HeadingCell>
            ))}
          </Row>
        ))}
      </Head>
      <Body {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <Row {...row.getRowProps()}>
              {row.cells.map((cell, bodyCellIndex) => {
                return (
                  <Cell key={'permissions-table-body-cell' + bodyCellIndex}>
                    {cell.render('Cell')}
                  </Cell>
                )
              })}
            </Row>
          )
        })}
      </Body>
    </Table>
  )
}
