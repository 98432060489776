import * as React from 'react'
import { Head, HeadingCell, Row } from '@toasttab/buffet-pui-table'

export function Header() {
  return (
    <Head>
      <Row>
        <HeadingCell>Field</HeadingCell>
        <HeadingCell>Content</HeadingCell>
        <HeadingCell>Action</HeadingCell>
      </Row>
    </Head>
  )
}
