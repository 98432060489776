export const TOAST_ADMIN_ROLE = 'TOAST_ADMIN'
export const PERMISSION_ADMIN_POSITION = 76

function hasPermission(permissions: string, bitPosition: number) {
  const zeroPosition = permissions.length - 1
  return permissions.charAt(zeroPosition - bitPosition) === '1'
}

export interface EditorAccess {
  universalAccess: boolean
  administratorManagement: boolean
}

export function getEditorAccess(
  editorRoles: string[],
  editorPermissions: string
): EditorAccess {
  return {
    universalAccess: editorRoles.includes(TOAST_ADMIN_ROLE),
    administratorManagement: hasPermission(
      editorPermissions,
      PERMISSION_ADMIN_POSITION
    )
  }
}
