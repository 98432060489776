import { ApiError } from '../types/ApiError'
import {
  METHOD_POST,
  ROUTE_USERS_CUSTOMERS,
  PARTIAL_ROUTE_VERIFICATION_RESET
} from './constants'

export const UNVERIFY_IDENTITY_KEY = 'unverify_identity_key'

export async function unverifyIdentity(userGuid: string) {
  const resetVerificationRoute = `${ROUTE_USERS_CUSTOMERS}/${userGuid}${PARTIAL_ROUTE_VERIFICATION_RESET}`
  const response = await fetch(resetVerificationRoute, {
    method: METHOD_POST,
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({})
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }
}
