import * as React from 'react'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { snackbarRoot, modalParentElementAttribute } from '../../utils/portals'

interface Children {
  children: React.ReactNode
}

export function PortalsSetup({ children }: Children) {
  return (
    <>
      <SnackBarProvider domRoot={snackbarRoot}>{children}</SnackBarProvider>
      {/* Fixes snackbar being shown below modals */}
      <div {...{ [modalParentElementAttribute]: true }} id={snackbarRoot}></div>
    </>
  )
}
