import * as React from 'react'
import { CloseCircleIcon, AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { getModalParentElement } from '../../utils/portals'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { SEARCH_USER_KEY } from '../../api/user'

interface UserViewActionProps {
  actionLabel: string
  modalHeader: string
  modalBodyContent: React.ReactElement
  successMessage: string
  errorMessage: string
  userGuid: string
  mutationKey: string
  mutationFn: (userGuid: string) => Promise<void>
}

export function UserViewAction({
  actionLabel,
  modalHeader,
  modalBodyContent,
  successMessage,
  errorMessage,
  userGuid,
  mutationKey,
  mutationFn
}: UserViewActionProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()

  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(
    mutationKey,
    () => mutationFn(userGuid),
    {
      onError() {
        showErrorSnackBar(errorMessage, {
          onDismiss() {}
        })
      },
      onSuccess() {
        queryClient.invalidateQueries(SEARCH_USER_KEY)
        showSuccessSnackBar(successMessage, {
          onDismiss() {}
        })
        setIsModalOpen(false)
      }
    }
  )

  return (
    <>
      <Button
        className='px-0'
        iconLeft={<CloseCircleIcon accessibility='decorative' />}
        variant='link'
        disabled={isModalOpen}
        onClick={() => setIsModalOpen(true)}
        size='sm'
      >
        {actionLabel}
      </Button>
      <Modal
        isOpen={isModalOpen}
        size='xxl'
        parentSelector={getModalParentElement}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalBody>{modalBodyContent}</ModalBody>
        <ModalFooter>
          <div className='flex w-full flex-row justify-between gap-2 md:justify-end'>
            <Button
              variant='link'
              onClick={() => setIsModalOpen(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant='destructive'
              disabled={isLoading}
              iconLeft={
                isLoading && (
                  <AutorenewIcon
                    accessibility='decorative'
                    className='animate-spin'
                  />
                )
              }
              onClick={() => mutate()}
            >
              {actionLabel}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}
