import * as React from 'react'
import { UserViewAction } from './UserViewAction'
import {
  unverifyIdentity,
  UNVERIFY_IDENTITY_KEY
} from '../../api/identityVerification'

interface UnverifyIdentityProps {
  userGuid: string
}

export function UnverifyIdentity({ userGuid }: UnverifyIdentityProps) {
  return (
    <UserViewAction
      actionLabel='Reset identity verification'
      modalHeader='Are you sure you want to reset this identity verification?'
      modalBodyContent={<UnverifyIdentityModalBody />}
      errorMessage='Identity verification could not be reset'
      successMessage='Identity verification successfuly reset'
      userGuid={userGuid}
      mutationKey={UNVERIFY_IDENTITY_KEY}
      mutationFn={unverifyIdentity}
    />
  )
}

const UnverifyIdentityModalBody = () => (
  <p>
    Users can always re-verify their identity at any time within their My
    Account page.
  </p>
)
