import * as React from 'react'
import { QueryStatus } from 'react-query'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@toasttab/buffet-pui-tabs'
import { OptionalUser } from '../../api/user'
import { AdministratorAccess } from '../AdministratorAccess'
import { NoUserFound, NoUserSelected } from '../EmptyState'
import { LoadingState } from '../LoadingState'
import { ViewUser } from '../ViewUser'

export interface MainContentProps {
  status: QueryStatus
  user?: OptionalUser
}

export function MainContent({ status, user }: MainContentProps) {
  if (status === 'idle') {
    return <NoUserSelected />
  } else if (status === 'loading') {
    return <LoadingState />
  } else if (!!user) {
    return (
      <Tabs>
        <TabList block>
          <Tab testId='user-details-tab'>User Details</Tab>
          <Tab testId='admin-access-tab'>Administrator Access</Tab>
        </TabList>
        <TabPanels>
          <TabPanel className='pt-4'>
            <ViewUser user={user} />
          </TabPanel>
          <TabPanel className='pt-4'>
            <AdministratorAccess
              userGuid={user.guid}
              access={user.adminAccess}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    )
  }

  return <NoUserFound />
}
