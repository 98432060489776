import { ApiError } from '../types/ApiError'
import { METHOD_GET, ROUTE_PERMISSIONS_ADMIN_INIT } from './constants'

export interface RoleEnum {
  name: string
  title: string
  permissions: string
}

export interface PermissionsEnum {
  title: string
  description: string | null
  positionBit: number
}

export interface PermissionsInit {
  roles: RoleEnum[]
  administrativePermissions: PermissionsEnum[]
}

export const PERMISSIONS_INIT_KEY = 'permissions_init_key'

export async function permissionsInit(): Promise<PermissionsInit> {
  const response = await fetch(ROUTE_PERMISSIONS_ADMIN_INIT, {
    method: METHOD_GET
  })

  if (!response.ok) {
    const body = (await response.json()) as ApiError
    throw new Error(body.message)
  }

  return (await response.json()) as PermissionsInit
}
